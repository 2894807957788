<template>
  <nav class="bottom-bar">
    <div class="bottom-bar-wrapper">
      <div class="flex">
        <ButtonIconTextFrameless
            v-if="showRequestSheetButton"
            @click.native="$emit('requestClick')"
            iconClass="bx bxs-file bx-xs"
            responsive="vertical"
            text="Request details"
        />
        <ButtonIconTextFrameless
            @click.native="$emit('summaryClick')"
            class="ml-0 xs:ml-1"
            text="Preview"
            responsive="vertical"
            iconClass="bx bxs-show bx-xs"
        />
      </div>
      <ButtonPrimary
          v-if="!isLoading"
          :text="btnPrimaryText"
          class="block px-3 xs:px-8 ml-auto"
          @click.native="$emit('primaryClick')"
      />
      <div class="block ml-auto py-2" v-if="isLoading">
        <Loading margin="my-0" :showLabel="true" :label="loadingMessage"/>
      </div>
    </div>
  </nav>
</template>
<script>
import ButtonIconTextFrameless from "@/components/Buttons/ButtonIconTextFrameless";
import ButtonPrimary from "@/components/Buttons/ButtonPrimary";
import Loading from "@/components/Preloaders/Loading";

export default {
  name: "BottomBar",
  props: {
    btnPrimaryText: {
      default: "Continue",
      type: String,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
    showRequestSheetButton: {
      type: Boolean,
      default: true,
    },
    loadingMessage: {
      type: String,
      default: "Loading...",
    },
  },
  components: {
    ButtonIconTextFrameless,
    ButtonPrimary,
    Loading,
  },
};
</script>
<style scoped>
.bottom-bar {
  @apply bg-white w-full px-2 xs:px-3 py-3 border-t border-grey-light fixed bottom-0 left-0 flex justify-center;
}

.bottom-bar-wrapper {
  @apply flex max-w-5xl w-full items-center;
}
</style>