import api from "@/api";
import dayjs from "dayjs";
import { tourDateRangeFromDurationISO } from "../../shared/dateHandler";

let startDay = dayjs().locale("en-UK").startOf("day").toISOString();
let stopDay = dayjs()
  .add(2, "day")
  .locale("en-UK")
  .startOf("day")
  .toISOString();

const initialTourDetails = {
  //details
  title: "",
  destination: "Algeria",
  description: "",
  agency: [],
  language: "",
  maxGroupSize: "",
  minAge: "",
  image: "",
  duration: 0,
  tourDates: [`${startDay}|${stopDay}`],
  //pricing
  currency: "USD",
  singleRoomPrice: "",
  sharedRoomPrice: "",
  earningFees: 0,
  inclusions: [],
  exclusions: [],
  experiences: "",
  accommodation: [],
  itinerary: [],
  //cancellation
  fullRefundDays: "",
  partialRefund: "",
  partialRefundDays: "",
  noRefundDays: "",
  totalPrice: 0,
};

export default {
  namespaced: true,
  state: {
    loadingMessage: "Updating tour",
    savingResponseMessage: "Success",
    savingResponseStatus: "Success",
    isSubmittingTour: false,
    shouldRedirectAfterOfferSave: false,
    showUnsavedChangesWarning: true,
    isLoadingData: false,
    showOfferSummary: false,
    tourDetails: initialTourDetails,
    tourBookings: [],
    groupTours: [],
    groupToursBookings: [],
    hasAlreadySavedOffer: false,
  },

  getters: {
    tourDetails(state) {
      return state.tourDetails;
    },
    groupTours(state) {
      return state.groupTours;
    },
    tourBookings(state) {
      return state.tourBookings;
    },
    groupToursBookings(state) {
      return state.groupToursBookings;
    },
    showOfferSummary(state) {
      return state.showOfferSummary;
    },
    savingResponseMessage(state) {
      return state.savingResponseMessage;
    },
    savingResponseStatus(state) {
      return state.savingResponseStatus;
    },
    isSubmittingTour(state) {
      return state.isSubmittingTour;
    },
    isLoadingData(state) {
      return state.isLoadingData;
    },
    loadingMessage(state) {
      return state.loadingMessage;
    },
    shouldRedirectAfterOfferSave(state) {
      return state.shouldRedirectAfterOfferSave;
    },
    showUnsavedChangesWarning(state) {
      return state.showUnsavedChangesWarning;
    },
  },

  mutations: {
    SET_LOADING_MESSAGE(state, value) {
      state.loadingMessage = value;
    },
    SET_SAVING_RESPONSE_MESSAGE(state, value) {
      state.savingResponseMessage = value;
    },
    SET_SAVING_RESPONSE_STATUS(state, value) {
      state.savingResponseStatus = value;
    },
    SET_IS_SUBMITTING_OFFER(state, value) {
      state.isSubmittingTour = value;
    },
    SET_IS_LOADING_DATA(state, value) {
      state.isLoadingData = value;
    },
    SET_HAS_ALREADY_SAVED_OFFER(state, value) {
      state.hasAlreadySavedOffer = value;
    },
    SET_GROUP_TOUR_DETAILS(state, value) {
      state.tourDetails = value;
    },
    SET_TOUR_BOOKINGS(state, value) {
      state.tourBookings = value;
    },
    SET_GROUP_TOURS_BOOKINGS(state, value) {
      state.groupToursBookings = value;
    },
    SET_REQUESTED_CHANGES(state, value) {
      state.requestedChanges = value;
    },
    SET_SHOW_OFFER_SUMMARY(state, value) {
      state.showOfferSummary = value;
    },
    SET_SHOULD_REDIRECT_AFTER_OFFER_SAVE(state, value) {
      state.shouldRedirectAfterOfferSave = value;
    },
    SET_SHOW_UNSAVED_CHANGES_WARNING(state, value) {
      state.showUnsavedChangesWarning = value;
    },
    SET_GROUP_TOURS(state, value) {
      state.groupTours = value;
    },
    SET_SELECTED_GROUP_TOUR(state, value) {
      state.selectedGroupTour = value;
    },
    REMOVE_AN_ITINERARY(state, index) {
      state.tourDetails.itinerary.splice(index, 1);
    },
    REMOVE_AN_ACCOMMODATION(state, index) {
      state.tourDetails.accommodation.splice(index, 1);
    },
    UNSET_ACCOMMODATIONS(state) {
      state.tourDetails.accommodation = [];
    },
    UNSET_ITINERARY(state) {
      state.tourDetails.itinerary = [];
    },
    UNSET_TOUR_BOOKINGS(state) {
      state.tourBookings = [];
    },
    UNSET_GROUP_TOURS_BOOKINGS(state) {
      state.groupToursBookings = [];
    },
    UNSET_GROUP_TOURS(state) {
      state.groupTours = [];
    },
  },

  actions: {
    addAccommodation({ state }, accommodation) {
      let getIndex = null;
      let data = state.tourDetails.accommodation;
      getIndex = data.findIndex((x) => {
        return x.id == parseInt(accommodation.id);
      });
      if (getIndex > -1) {
        state.tourDetails.accommodation.splice(getIndex, 1, accommodation);
      } else {
        state.tourDetails.accommodation.splice(
          accommodation.id,
          0,
          accommodation
        );
      }
    },
    addItinerary({ state }, itineraryDetail) {
      let data = state.tourDetails.itinerary;
      let getIndex = data.findIndex((x) => {
        return x.id == itineraryDetail.id;
      });
      if (getIndex > -1) {
        //if the index is greater than -1 then the item exists
        state.tourDetails.itinerary.splice(getIndex, 1, itineraryDetail);
      } else {
        //else the item does not exist
        state.tourDetails.itinerary.splice(
          itineraryDetail.id,
          0,
          itineraryDetail
        );
      }
    },

    removeItinerary({ state, commit }, id) {
      let data = state.tourDetails.itinerary;
      let getIndex = data.findIndex((x) => {
        return x.id === id;
      });
      commit("REMOVE_AN_ITINERARY", getIndex);
    },

    removeAccommodation({ state, commit }, id) {
      let data = state.tourDetails.accommodation;
      let getIndex = data.findIndex((x) => x.id === id);
      if (getIndex > -1) {
        commit("REMOVE_AN_ACCOMMODATION", getIndex);
      }
    },

    resetAccommodations({ commit }) {
      commit("UNSET_ACCOMMODATIONS");
    },

    resetItinerary({ commit }) {
      commit("UNSET_ITINERARY");
    },

    setTripDetails({ commit }, tripDetails) {
      commit("SET_GROUP_TOUR_DETAILS", tripDetails);
    },

    async submitGroupTour({ commit, state }, saveType = "publish") {
      commit("SET_SAVING_RESPONSE_STATUS", null);
      commit("SET_IS_SUBMITTING_OFFER", true);
      let url = "";

      function compare(a, b) {
        if (a.id < b.id) {
          return -1;
        }
        if (a.id > b.id) {
          return 1;
        }
        return 0;
      }

      if (saveType === "draft") {
        url = "/save-tour-draft";
        commit("SET_LOADING_MESSAGE", "Saving to drafts");
      } else {
        if (state.tourDetails.id) {
          url = "/update-tour";
        } else {
          url = "/save-new-tour";
        }
        commit("SET_LOADING_MESSAGE", "Updating tour");
      }

      //sort itinerary list
      let sortedItinerary = state.tourDetails.itinerary;
      sortedItinerary = sortedItinerary.sort(compare);

      //sort accommodation list
      let sortedAccommodations = state.tourDetails.accommodation;
      sortedAccommodations = sortedAccommodations.sort(compare);

      let dataToSubmit = {
        ...state.tourDetails,
        accommodation: JSON.stringify(sortedAccommodations),
        agency:
          typeof state.tourDetails.agency === "object"
            ? state.tourDetails.agency[0]
            : state.tourDetails.agency,
        itinerary: JSON.stringify(sortedItinerary),
        tourDates: JSON.stringify(state.tourDetails.tourDates),
      };

      if (state.tourDetails.id) {
        dataToSubmit.tourId = state.tourDetails.id;
      }

      if (typeof state.tourDetails.image === "string") {
        delete dataToSubmit.image;
      }

      //setting the tour id to its original if there is an existing tour when you are not saving as draft
      if (state.hasAlreadySavedOffer && saveType !== "draft") {
        dataToSubmit.tourid = state.tourDetails.originalTourId;
      }

      // this.disableSubmitButton = true;
      api
        .post(url, dataToSubmit, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          commit("SET_SAVING_RESPONSE_MESSAGE", data.message);
          commit("SET_SAVING_RESPONSE_STATUS", data.response);
          commit("SET_IS_SUBMITTING_OFFER", false);
          commit("SET_SHOW_UNSAVED_CHANGES_WARNING", false);
          commit("SET_GROUP_TOUR_DETAILS", initialTourDetails);
          commit("SET_SHOULD_REDIRECT_AFTER_OFFER_SAVE", saveType !== "draft");
        })
        .catch((error) => {
          commit("SET_SAVING_RESPONSE_MESSAGE", error.response.data.message);
          commit("SET_SAVING_RESPONSE_STATUS", "failed");
          commit("SET_IS_SUBMITTING_OFFER", false);
          commit("SET_SHOULD_REDIRECT_AFTER_OFFER_SAVE", false);
          commit("SET_SHOW_UNSAVED_CHANGES_WARNING", true);
        });
    },

    async dateChanged({ commit }) {
      commit("UNSET_ACCOMMODATIONS");
      commit("UNSET_ITINERARY");
    },

    async cancelOffer({ commit }, requestID) {
      commit("SET_IS_LOADING_DATA", false);
      let response = await api.post(`/cancel-tour/${requestID}`);
      return response;
    },

    getGroupTours({ commit }) {
      commit("SET_IS_LOADING_DATA", true);
      commit("UNSET_GROUP_TOURS_BOOKINGS");
      commit("UNSET_GROUP_TOURS");

      api
        .getAll(["/tours", "/tours/bookings"])
        .then((response) => {
          commit("SET_GROUP_TOURS", response[0].data.tours);
          commit("SET_GROUP_TOURS_BOOKINGS", response[1].data.tourBookings);
        })
        .finally(() => {
          commit("SET_IS_LOADING_DATA", false);
        });
    },

    getGroupTour({ commit }, tourId) {
      commit("SET_IS_LOADING_DATA", true);
      return api
        .get(`/tours/${tourId}`)
        .then((response) => {
          const tour = response.data.tour;
          tour.accommodation = JSON.parse(tour.accommodation);
          tour.itinerary = JSON.parse(tour.itinerary);
          tour.agency = [tour.agency];
          tour.tourDates = tour.dates.map((date) => date.tourDate);
          if (
            tour.tourDates.length === 0 &&
            (tour.accommodation.length > 0 || tour.itinerary.length > 0)
          ) {
            tour.tourDates.push(
              tourDateRangeFromDurationISO(
                tour.itinerary[0].fullDate ||
                  tour.accommodation[0].fullCheckinDate,
                tour.duration
              )
            );
          }

          delete tour.dates;
          commit("SET_GROUP_TOUR_DETAILS", tour);
          return response;
        })
        .catch(() => {
          commit("SET_GROUP_TOUR_DETAILS", null);
        })
        .finally(() => {
          commit("SET_IS_LOADING_DATA", false);
        });
    },

    deleteGroupTour({ commit }, tourId) {
      commit("SET_IS_LOADING_DATA", true);
      return api
        .delete(`/tours/${tourId}`)
        .then((response) => {
          return response;
        })
        .finally(() => {
          commit("SET_IS_LOADING_DATA", false);
        });
    },

    getGroupTourBookings({ commit }, tourId) {
      commit("UNSET_TOUR_BOOKINGS");
      commit("SET_IS_LOADING_DATA", true);
      return api
        .get(`/tours/${tourId}/bookings`)
        .then((response) => {
          commit("SET_TOUR_BOOKINGS", response.data.tourBookings);
          return response;
        })
        .catch()
        .finally(() => {
          commit("SET_IS_LOADING_DATA", false);
        });
    },

    getGroupToursBookings({ commit }) {
      commit("UNSET_GROUP_TOURS_BOOKINGS");
      commit("SET_IS_LOADING_DATA", true);
      return api
        .get(`/tours/bookings`)
        .then((response) => {
          commit("SET_GROUP_TOURS_BOOKINGS", response.data.tourBookings);
          return response;
        })
        .finally(() => {
          commit("SET_IS_LOADING_DATA", false);
        });
    },

    deleteDateFromBackend(_, data) {
      return api.delete(`/tours/${data.tourId}/${data.date}`);
    },

    resetGroupTourDetails({ commit }) {
      commit("SET_GROUP_TOUR_DETAILS", initialTourDetails);
    },
  },
};
