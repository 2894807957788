<template>
  <div>
    <Divider class="mb-8 mt-12" />
    <div class="mb-3 sm:mb-6 flex items-center justify-between">
      <slot name="label">
        <div class="font-sans font-bold text-black-base text-base sm:text-xl">
          {{ mainLabel }}
        </div>
      </slot>

      <div class="flex gap-2">
        <ButtonIconText
          :iconClass="showHiddenDates ? 'bx bxs-hide' : 'bx bxs-show'"
          class="text-sm px-3"
          v-if="hasHiddenItems && expiredDates.length"
          @click.native.prevent="showHiddenDates = !showHiddenDates"
        >
          {{ `${showHiddenDates ? "Hide" : "Show"} expired dates` }}
        </ButtonIconText>
        <ButtonPrimary
          @click.native="addDate"
          text="Add a new date"
          class="px-3 ml-auto block"
        />
      </div>
    </div>
    <template v-for="(dateInput, index) in dates">
      <template v-if="isActiveDate(dateInput)">
        <div :key="index + '_date_input_available'" class="mb-6">
          <div class="input-wrapper mb-2">
            <label class="w-full mb-2 sm:mb-0">
              <span class="input-label" v-if="showLabels">Start date</span>
            </label>
            <div class="relative w-full">
              <date-picker
                v-model="dates[index]"
                valueType="format"
                format="ddd, D MMM YYYY"
                :disabled="
                  disablePreSavedDates &&
                  savedDates.includes(activeDates[index])
                "
              ></date-picker>
              <button
                @click="removeDate(activeDates[index])"
                type="button"
                class="delButton"
                v-if="
                  disablePreSavedDates &&
                  !savedDates.includes(activeDates[index])
                "
              >
                <i class="bx bx-trash"></i>
              </button>
            </div>
          </div>
          <div>
            <slot name="additionalDetails" :date="dateInput"></slot>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>
<script>
import dayjs from "dayjs";
import DatePicker from "vue2-datepicker";
import { getCurrentDate } from "../../shared/dateHandler";
import ButtonPrimary from "../Buttons/ButtonPrimary";
import Divider from "../Layout/Divider";
import ButtonIconText from "../Buttons/ButtonIconText";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export default {
  name: "MultiDatePicker",
  props: {
    value: {
      type: Array,
    },
    preSavedDates: {
      type: Array,
      required: false,
    },
    index: {
      type: [String, Number],
      default: 0,
    },
    showLabels: {
      type: Boolean,
      default: false,
    },
    disablePreSavedDates: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "start-date-picker",
    },
    mainLabel: {
      type: String,
      default: "Other Available Dates",
    },
    hasHiddenItems: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ButtonIconText,
    ButtonPrimary,
    Divider,
    DatePicker,
  },
  data() {
    return {
      startDate: "",
      dates: [],
      savedDates: [],
      isDateDropperInitialized: "",
      showHiddenDates: false,
    };
  },
  mounted() {
    if (this.value.length > 0) {
      this.dates = this.value.map((d) => dayjs(d).format("ddd, D MMM YYYY"));
      this.savedDates = this.preSavedDates.map((d) =>
        dayjs(d).format("ddd, D MMM YYYY")
      );
    }
  },
  methods: {
    updateDate(newDate = this.startDate) {
      this.$emit(
        "input",
        newDate.map((d) => `${dayjs(d).format("YYYY-MM-DD")}T00:00:00.000Z`)
      );
    },
    addDate() {
      let currentDate = getCurrentDate();

      //check if last date is < current date
      const lastDate = this.dates[this.dates.length - 1];
      if (lastDate) {
        currentDate = dayjs(lastDate)
          .add(1, "months")
          .format("ddd, D MMM YYYY");
      } else {
        currentDate = dayjs().add(1, "months").format("ddd, D MMM YYYY");
      }

      if (this.savedDates.includes(currentDate)) {
        currentDate = dayjs(currentDate)
          .add(5, "months")
          .format("ddd, D MMM YYYY");
      }

      this.dates.push(currentDate);
      this.$emit(
        "input",
        this.dates.map((d) => `${dayjs(d).format("YYYY-MM-DD")}T00:00:00.000Z`)
      );
    },
    isActiveDate(date) {
      return (
        dayjs().isSame(dayjs(date), "day") ||
        dayjs().isBefore(dayjs(date), "day")
      );
    },
    removeDate(date) {
      const index = this.dates.indexOf(date);
      this.dates.splice(index, 1);
    },
    dateAlreadySelected(date) {
      return this.savedDates.includes(dayjs(date).format("ddd, D MMM YYYY"));
    },
  },
  watch: {
    dates(newValue) {
      this.$emit(
        "input",
        newValue.map((d) => `${dayjs(d).format("YYYY-MM-DD")}T00:00:00.000Z`)
      );
    },
    preSavedDates(newVal) {
      if (newVal.length) {
        this.savedDates = newVal.map((d) => dayjs(d).format("ddd, D MMM YYYY"));
      }
    },
  },
  computed: {
    activeDates() {
      return this.dates.filter(
        (date) =>
          dayjs().isSame(dayjs(date), "day") ||
          dayjs().isBefore(dayjs(date), "day")
      );
    },
    expiredDates() {
      return this.dates.filter(
        (date) =>
          !dayjs().isSame(dayjs(date), "day") &&
          !dayjs().isBefore(dayjs(date), "day")
      );
    },
  },
};
</script>
<style scoped>
@import "../../assets/css/vue-datepicker.css";

.input-wrapper {
  @apply flex flex-wrap justify-between relative;
}

.delButton {
  @apply bg-red-light focus:outline-none hover:bg-red-lighter;
  @apply duration-300 transition-all px-4 py-1 sm:py-2 rounded text-red-text;
  @apply ml-4 absolute top-1/2 right-1.5 transform -translate-y-1/2;
  @apply text-base;
}

.input-label {
  @apply mb-1.5 sm:mb-3 font-sans font-medium text-black-base block;
  @apply text-sm sm:text-base;
}
</style>
