import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
const tz = "Europe/Zurich";
dayjs.tz.setDefault(tz);

const parseFormats = [
  "D-M-YYYY",
  "DD-M-YYYY",
  "DD-MM-YYYY",
  "DD/M/YYYY",
  "D/M/YYYY",
  "DD/MM/YYYY",
  "ddd, D MMM YYYY",
  "ddd, DD MMM YYYY",
  "ddd, D MMMM YYYY",
  "ddd, DD MMMM YYYY",
];

const isoFormatRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

const isISO8601 = function (date) {
  return isoFormatRegex.test(date);
};

const isFormat = function (date) {
  return dayjs(date, parseFormats, true).isValid();
};

const validateDate = function (date) {
  let d = undefined;
  if (isISO8601(date) === true) {
    d = dayjs.utc(date.split("T")[0] + "T00:00:00.000Z");
    return d;
  }

  if (isFormat(date) === true) {
    date = dayjs.utc(date).locale(locale).toISOString();
    d = dayjs.utc(date.split("T")[0] + "T00:00:00.000Z");
    return d;
  }
};

const locale = "en-UK";
const monthMap = {
  Jan: "January",
  Feb: "February",
  Mar: "March",
  Apr: "April",
  May: "May",
  Jun: "June",
  Jul: "July",
  Aug: "August",
  Sep: "September",
  Oct: "October",
  Nov: "November",
  Dec: "December",
  Janu: "January",
  Febr: "February",
  Marc: "March",
  Apri: "April",
  June: "June",
  July: "July",
  Augu: "August",
  Sept: "September",
  Octo: "October",
  Nove: "November",
  Dece: "December",
};
const frenchDays = ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"];
const englishDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const frenchMonths = [
  "Jan",
  "Fév",
  "Mars",
  "Avr",
  "Mai",
  "Juin",
  "Juil",
  "Août",
  "Sept",
  "Oct",
  "Nov",
  "Déc",
];
const englishMonths = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function replaceFrenchDatesWithEnglish(string) {
  // Replace French days with English days
  for (let i = 0; i < frenchDays.length; i++) {
    const regex = new RegExp(`\\b${frenchDays[i]}\\b`, "g");
    string = string.replace(regex, englishDays[i]);
  }

  // Replace French months with English months
  for (let i = 0; i < frenchMonths.length; i++) {
    const regex = new RegExp(`\\b${frenchMonths[i]}\\b`, "g");
    string = string.replace(regex, englishMonths[i]);
  }

  return string;
}

function isDateInFrenchFormat(dateString) {
  const frenchDatePattern =
    /^(Lun|Mar|Mer|Jeu|Ven|Sam|Dim), \d{1,2} (Jan|Fév|Mars|Avr|Mai|Juin|Juil|Août|Sept|Oct|Nov|Déc) \d{4}$/;
  return frenchDatePattern.test(dateString);
}

function replaceMonthAbbreviations(str) {
  if (isDateInFrenchFormat(str)) {
    str = replaceFrenchDatesWithEnglish(str);
  } else {
    for (const key in monthMap) {
      const regex = new RegExp(`\\b${key}\\b`, "g");
      str = str.replace(regex, monthMap[key]);
    }
  }

  return str;
}

function convertNonIsoDateStringsToISO(dateString) {
  dateString = replaceMonthAbbreviations(dateString);

  // Remove the day from the string
  dateString = dateString.replace(/^[A-Za-z]{3},\s/, "");
  // Replace English months with corresponding numbers
  englishMonths.forEach((month, index) => {
    dateString = dateString.replace(
      new RegExp(month, "g"),
      (index + 1).toString().padStart(2, "0")
    );
  });
  // Extract day, month, and year from the modified string
  const [day, month, year] = dateString.match(/\d+/g);

  // Create the ISO date string in "YYYY-MM-DD" format
  const isoDate = `${year}-${month}-${
    day.length === 1 ? 0 : ""
  }${day}T00:00:00.000Z`;
  return isoDate;
}

export const splitDates = (dates) => {
  let splitDate = [];
  if (dates.includes("/") || dates.includes(",")) {
    if (dates.includes("-") && !dates.includes("|")) {
      splitDate = dates.split("-");
    } else {
      splitDate = dates.split("|");
    }
  } else {
    splitDate = dates.split("|");
  }
  return splitDate.map((d) => replaceMonthAbbreviations(d.trim()));
};

const dayDiff = (dates) => {
  let splitDate = splitDates(dates);
  const startDate = validateDate(splitDate[0]);
  const endDate = validateDate(splitDate[1]);
  return endDate.diff(startDate, "day", false);
};

const dayISODiff = (dates) => {
  let splitDate = splitDates(dates);
  const startDate = validateDate(splitDate[0]);
  const endDate = validateDate(splitDate[1]);
  return endDate.diff(startDate, "day", false);
};

export const tourDateRangeFromDuration = (
  startDate = new Date(),
  duration = 0,
  offsetStartDate = 0,
  options = {
    inclusive: false,
  }
) => {
  let finalDuration = duration;
  if (!options.inclusive) {
    finalDuration = duration - 1;
  }
  startDate = !offsetStartDate
    ? startDate
    : validateDate(startDate)
        .add(offsetStartDate, "day")
        .format("ddd, D MMM YYYY");

  return `${startDate}|${validateDate(startDate)
    .add(finalDuration, "day")
    .format("ddd, D MMM YYYY")}`;
};

export const tourDateRangeFromDurationISO = (
  startDate,
  duration,
  offsetStartDate = 0,
  options = {
    inclusive: false,
  }
) => {
  let finalDuration = duration;
  if (!options.inclusive) {
    finalDuration = duration - 1;
  }

  if (startDate.includes("/") || startDate.includes(",")) {
    startDate = !offsetStartDate
      ? startDate
      : validateDate(startDate)
          .add(offsetStartDate, "day")
          .locale(locale)
          .startOf("day")
          .toISOString();

    return `${startDate}|${validateDate(startDate)
      .add(finalDuration, "day")
      .locale(locale)
      .startOf("day")
      .toISOString()}`;
  } else {
    startDate = !offsetStartDate
      ? startDate
      : dayjs
          .utc(startDate)
          .add(offsetStartDate, "day")
          .locale(locale)
          .startOf("day")
          .toISOString();

    return `${startDate}|${dayjs
      .utc(startDate)
      .add(finalDuration, "day")
      .locale(locale)
      .startOf("day")
      .toISOString()}`;
  }
};

export const tourEndDateFromDuration = (startDate, duration) => {
  return `${dayjs
    .utc(startDate)
    .add(duration, "day")
    .startOf("day")
    .locale(locale)
    .format("D/M/YYYY")}`;
};

export const isUndefined = (val) => {
  return val === undefined || val === "undefined";
};

export const humanReadableDateRange = (dates) => {
  let startDate,
    endDate = "";
  let splitDate = splitDates(dates);
  if (isUndefined(splitDate[0]) || isUndefined(splitDate[1])) {
    return "Dates not known";
  }
  if (!isISO8601(splitDate[0])) {
    splitDate[0] = convertNonIsoDateStringsToISO(splitDate[0]);
  }
  if (!isISO8601(splitDate[1])) {
    splitDate[1] = convertNonIsoDateStringsToISO(splitDate[1]);
  }

  startDate = validateDate(splitDate[0]);
  endDate = validateDate(splitDate[1]);
  return `${startDate.format("ddd, D MMM YYYY")} - ${endDate.format(
    "ddd, D MMM YYYY"
  )}`;
};

export const getCurrentDate = (
  date = new Date(),
  format = "ddd, D MMM YYYY"
) => {
  return dayjs.utc(date).startOf("day").format(format);
};

export const reformatDatesToIso = (dates) => {
  let splitDate = splitDates(dates);
  return `${validateDate(splitDate[0]).toISOString()}|${validateDate(
    splitDate[1]
  ).toISOString()}`;
};

export const getStartDate = (dates) => {
  let splitDate = splitDates(dates);
  return validateDate(splitDate[0]);
};

export const getEndDate = (dates) => {
  let splitDate = splitDates(dates);
  return validateDate(splitDate[1]);
};

export const getNumDaysFromString = (dates) => {
  if (dates.includes("/") || dates.includes(",")) {
    return dayDiff(dates) + 1;
  }
  return dayISODiff(dates) + 1;
};

export const getNumNightsFromString = (dates) => {
  if (dates.includes("/") || dates.includes(",")) {
    return dayDiff(dates);
  }
  return dayISODiff(dates);
};

export const generatedArrayOfDatesFromDates = (dates) => {
  let startDate = getStartDate(dates);
  const totalDays = getNumDaysFromString(dates);
  const arr = [];
  for (let i = 0; i < totalDays; i++) {
    arr.push({
      date: startDate.toISOString(),
    });
    startDate = startDate.add(1, "day");
  }
  return arr;
};

export const generatedArrayOfDatesFromDateAndDuration = (date, duration) => {
  let startDate = validateDate(date);
  const arr = [];
  for (let i = 0; i < duration; i++) {
    arr.push({
      date: startDate.toISOString(),
    });
    startDate = startDate.add(1, "day");
  }
  return arr;
};

export const generateDays = (dates) => {
  let startDate = getStartDate(dates);
  const days = [];
  let totalDays = getNumDaysFromString(dates);
  for (let day = 0; day < totalDays; day++) {
    let actualStartDate = `${startDate.format("D MMMM")}`;
    let fullActualStartDate = `${startDate.toISOString()}`;
    days.push({
      id: day,
      date: actualStartDate,
      fullDate: fullActualStartDate,
      class: "mb-3",
      disAttribute: false,
      active: false,
      showExperiences: false,
      showPlaceholder: true,
      showEmptyState: false,
      showEditor: false,
      experiences: [],
      previousExperiencesState: [],
      showPlaceHolderExperiences: false,
      dayTitle: "",
      showTitle: false,
      showSummary: false,
      placeholderExperiences: "",
    });
    startDate = startDate.add(1, "day");
  }
  return days;
};

export const generateNights = (dates) => {
  const numberOfNights = getNumNightsFromString(dates);
  let startDate = getStartDate(dates);
  const nights = [];
  for (let night = 1; night <= numberOfNights; night++) {
    let actualStartDate = startDate.format("D MMMM");
    let fullActualStartDate = startDate.toISOString();
    nights.push({
      id: night,
      startDate: actualStartDate,
      fullStartDate: fullActualStartDate,
      showSummary: false,
      showBlock: true,
      showNightSelect: true,
      showPlaceholder: true,
      showInputFields: false,
      active: true,
      overnightStays: undefined,
      nightsAvailable: [],
      disablePlaceholderBtn: false,
      blockOpacity: "opacity-100",
      amountOfNights: 0,
      accommodation: {
        id: night,
        hotel: {
          name: "",
          image: "",
          address: "",
        },
        mealplan: {
          breakfast: false,
          lunch: false,
          dinner: false,
        },
        numberOfNights: 0,
        checkin: actualStartDate,
        fullCheckinDate: fullActualStartDate,
        checkout: "",
        fullCheckoutDate: null,
      },
    });
    startDate = startDate.add(1, "day");
  }
  return nights;
};

export const checkIfDatesAreEqual = (date1, date2) => {
  let d1 = undefined;
  let d2 = undefined;
  if (date1.includes(",")) d1 = convertNonIsoDateStringsToISO(date1);
  else d1 = validateDate(date1);
  if (date2.includes(",")) d2 = convertNonIsoDateStringsToISO(date2);
  else d2 = validateDate(date2);
  return dayjs(d1).toISOString() === dayjs(d2).toISOString();
};

export const generateNewDateFromDuration = (dateString, duration = 0) => {
  if (dateString.includes(","))
    dateString = convertNonIsoDateStringsToISO(dateString);
  else dateString = validateDate(dateString);
  return dayjs(dateString).add(duration, "day");
};

export const defaultStartStopDates = () => {
  const startDay = dayjs.utc().startOf("day").startOf("day").toISOString();
  const stopDay = dayjs.utc().add(2, "day").startOf("day").toISOString();
  return `${startDay}|${stopDay}`;
};

export const checkAndConvertDateToIso = (date) => {
  if (isISO8601(date)) return date;
  return convertNonIsoDateStringsToISO(date);
};
