<template>
  <section id="itinerary">
    <h1 class="form-page-title">Itinerary</h1>
    <ExperiencesConfigurator
        @createExperience="$emit('createExperience')"
        :alreadyHasItinerary="alreadyHasItinerary"
        :destination="tripDetails.tripDestination"
        :dates="tripDetails.tripDates"
        :selectedItinerary="tripDetails.tripItinerary"
        @openSheet="emitOpenSheet"
        @reset="resetItinerary"
        @removeItinerary="removeItineraryFromTour"
        @addItinerary="addItineraryToTour"
    />
  </section>
</template>
<script>
import ExperiencesConfigurator from "@/components/Offer/Itinerary/ExperiencesConfigurator";
import {mapActions, mapGetters} from "vuex";
export default {
  name: "Itinerary",
  components: {
    ExperiencesConfigurator,
  },
  props: {
    alreadyHasItinerary: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions({
      'setShowExperienceListSheet':'experiences/setShowExperienceListSheet',
      addItinerary: 'offer/addItinerary',
      removeItinerary: 'offer/removeItinerary',
      resetItinerary: 'offer/resetItineraryDetails'
    }),
    addItineraryToTour(itinerary) {
      this.addItinerary(itinerary);
    },
    removeItineraryFromTour(index) {
      this.removeItinerary(index);
    },
    emitOpenSheet() {
      this.$emit("openSheet");
      this.setShowExperienceListSheet(true);
    },
  },
  computed: {
    ...mapGetters({
      tripDetails: 'offer/tripDetails',
    }),
  }
};
</script>
<style scoped>
#itinerary {
  @apply pb-44;
}

.form-page-title {
  @apply font-sans font-bold text-black-base text-xl mt-12 sm:text-3xl mb-4 sm:mb-8;
}
</style>
