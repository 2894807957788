<template>
  <div class="requests bg-grey-light h-100vh overflow-hidden">
    <div class="px-4 md:px-12">
      <FormNavbar
        :currentStepItemIndex="currentStepItemIndex"
        :isLoading="isSubmittingTour"
        class="w-full max-w-5xl relative left-1/2 transform -translate-x-1/2"
        @currentMultiStepItem="getCurrentMultiStepItem"
        @exit="exitTripCreationForm"
        @saveToDraft="submitGroupTour"
        :multiStepItems="groupOfferCreationSteps"
        @redirectTo="redirectTo"
        :showSaveToDraft="false"
      />
    </div>
    <template v-if="isLoadingData">
      <Loading />
    </template>
    <template v-else>
      <Dialog
        v-show="showDialog"
        class="dialog"
        text=""
        title="Do you really want to remove this Item?"
        @cancelDialog="closeSheets"
      />
      <BackgroundLayer
        v-show="showOverlay"
        class="fixed z-10 top-0"
        @click.native="closeSheets()"
      />
      <div class="self-start w-full relative h-full overflow-y-auto">
        <section class="px-4 md:px-12 mt-5 pb-12">
          <div class="max-w-5xl relative left-1/2 transform -translate-x-1/2">
            <keep-alive>
              <ValidationObserver tag="form" ref="valObserver">
                <router-view />
              </ValidationObserver>
            </keep-alive>
          </div>
        </section>
        <button
          v-if="isPricing"
          class="btn-price"
          type="button"
          @click="showPricingSheet"
        >
          <i class="bx bxs-dollar-circle bx-xs"></i>
          <span>Show price summary</span>
        </button>
        <HotelSheet
          :class="['sheet', hotelSheetPosition]"
          :destination="tourDetails.destination"
          @closeSheet="closeSheets"
          @newAccommodationAdded="newAccommodationAdded"
        />
        <TourSummarySheet
          :class="['sheet', summarySheetPosition]"
          :isLastStep="isLastStep"
          :isLoading="isSubmittingTour"
          :tourDetails="tourDetails"
          :dates="tourDetails.tourDates[0]"
          @closeSheet="closeSheets"
          @submitClicked="submitGroupTour"
        />

        <ExperienceSheet
          :class="['sheet', experienceSheetPosition]"
          :experiences="[]"
          @closeSheet="closeSheets"
        />
        <ExperienceListSheet
          :class="['sheet', experienceSheetListPosition]"
          :experiences="experiences"
          @closeSheet="closeSheets"
          @createExperience="showExperienceSheet"
        />
        <BottomBar
          :btnPrimaryText="buttonText"
          :isLoading="isSubmittingTour"
          :loadingMessage="loadingMessage"
          :showRequestSheetButton="false"
          @primaryClick="moveToNextTabLink"
          @summaryClick="showSummarySheet"
        />
      </div>
    </template>
  </div>
</template>
<script>
import {
  BackgroundLayer,
  BottomBar,
  Dialog,
  ExperienceListSheet,
  Loading,
  HotelSheet,
  FormNavbar,
  TourSummarySheet,
  ExperienceSheet,
} from "@/components";
import { mapActions, mapGetters } from "vuex";
import { ValidationObserver } from "vee-validate";
import tripCreationMixin from "../../mixins/tripCreation.mixin";

export default {
  name: "Offer",
  mixins: [tripCreationMixin],
  metaInfo: {
    title: "Group Tours",
  },
  components: {
    BackgroundLayer,
    BottomBar,
    ExperienceSheet,
    ExperienceListSheet,
    Dialog,
    FormNavbar,
    HotelSheet,
    TourSummarySheet,
    Loading,
    ValidationObserver,
  },
  props: {
    tourId: {
      type: [Number, String],
      required: false,
    },
  },
  data() {
    return {
      newAccommodation: "",
      accommodationDetails: {},
      itineraryDetails: {},
      pricingDetails: {},
      cancellationDetails: {},
      request: {},
      formSections: [
        { name: "trip-details", show: true },
        { name: "accommodations", show: false },
        { name: "itinerary", show: false },
        { name: "pricing", show: false },
      ],
    };
  },
  methods: {
    ...mapActions({
      getGroupTour: "groupTours/getGroupTour",
      setShowExperienceListSheet: "experiences/setShowExperienceListSheet",
      setShowAccommodationSheet: "accommodations/setShowAccommodationSheet",
      submitGroupTour: "groupTours/submitGroupTour",
      resetGroupTourDetails: "groupTours/resetGroupTourDetails",
    }),
    newAccommodationAdded(value) {
      this.closeSheets();
      this.newAccommodation = value;
    },
    toggleDialog() {
      this.showDialog = !this.showDialog;
      this.showOverlay = !this.showOverlay;
    },
    async moveToNextTabLink() {
      const isValid = await this.$refs.valObserver.validate();

      if (isValid) {
        if (this.tourId) {
          switch (this.$route.name) {
            case "EditGroupTourDetails":
              this.$router.replace({ name: "EditGroupTourItinerary" });
              break;
            case "EditGroupTourItinerary":
              this.$router.replace({ name: "EditGroupTourAccommodations" });
              break;
            case "EditGroupTourAccommodations":
              this.$router.replace({ name: "EditGroupTourPricing" });
              break;
            case "EditGroupTourPricing":
              this.$router.replace({ name: "EditGroupTourCancellation" });
              break;
            case "EditGroupTourCancellation":
              this.showSummarySheet();
              break;
          }
        } else {
          switch (this.$route.name) {
            case "CreateGroupTourDetails":
              this.$router.replace({ name: "CreateGroupTourItinerary" });
              break;
            case "CreateGroupTourItinerary":
              this.$router.replace({ name: "CreateGroupTourAccommodations" });
              break;
            case "CreateGroupTourAccommodations":
              this.$router.replace({ name: "CreateGroupTourPricing" });
              break;
            case "CreateGroupTourPricing":
              this.$router.replace({ name: "CreateGroupTourCancellation" });
              break;
            case "CreateGroupTourCancellation":
              this.showSummarySheet();
              break;
          }
        }
      }
    },
    async redirectTo(routeName) {
      const isValid = await this.$refs.valObserver.validate();

      if (isValid) {
        await this.$router.push({ name: routeName });
      }
    },
    exitTripCreationForm() {
      if (this.showUnsavedChangesWarning) {
        if (
          window.confirm(
            "Are you sure you want to leave the page? \nIf you leave without saving, your changes will be lost."
          )
        ) {
          this.$router.go(-1);
          this.resetGroupTourDetails();
        }
      } else {
        this.$router.go(-1);
        this.resetGroupTourDetails();
      }
    },
  },
  beforeMount() {
    if (this.tourId) {
      this.getGroupTour(this.tourId).then(() => {
        this.pageIsLoading = false;
      });
    } else {
      this.resetGroupTourDetails();
    }

    if (!this.$route.query.customer) {
      this.customerId = this.request.user_id;
    } else {
      this.customerId = this.$route.query.customer;
    }
    if (this.$route.meta.isLastStep) {
      this.isLastStep = this.$route.meta.isLastStep;
    }
  },
  computed: {
    ...mapGetters({
      tourDetails: "groupTours/tourDetails",
      selectedGroupTour: "groupTours/selectedGroupTour",
      experiences: "experiences/experiences",
      showExperienceListSheetState: "experiences/showExperienceListSheetState",
      showAccommodationSheet: "accommodations/showAccommodationSheet",
      showExperienceSheetState: "experiences/showExperienceSheetState",
      isSubmittingTour: "groupTours/isSubmittingTour",
      loadingMessage: "groupTours/loadingMessage",
      isLoadingData: "groupTours/isLoadingData",
      savingResponseStatus: "groupTours/savingResponseStatus",
      savingResponseMessage: "groupTours/savingResponseMessage",
      shouldRedirectAfterOfferSave: "groupTours/shouldRedirectAfterOfferSave",
      showUnsavedChangesWarning: "groupTours/showUnsavedChangesWarning",
    }),
    buttonText() {
      //check if the form is on the last index
      let getIndex = this.formSections.findIndex((x) => x.show == true);
      if (getIndex + 1 < this.formSections.length) {
        return "Continue";
      } else {
        return "Submit";
      }
    },
    isPricing() {
      let isPricing = this.$route.path.includes("pricing");
      return isPricing;
    },
    groupOfferCreationSteps() {
      return [
        {
          text: "GroupTours details",
          name: "tour-details",
          linkName: this.tourId
            ? "EditGroupTourDetails"
            : "CreateGroupTourDetails",
          isActive: false,
        },
        {
          text: "Itinerary",
          name: "itinerary",
          linkName: this.tourId
            ? "EditGroupTourItinerary"
            : "CreateGroupTourItinerary",
          isActive: false,
        },
        {
          text: "Accommodations",
          name: "accommodations",
          linkName: this.tourId
            ? "EditGroupTourAccommodations"
            : "CreateGroupTourAccommodations",
          isActive: false,
        },
        {
          text: "Pricing",
          name: "pricing",
          linkName: this.tourId
            ? "EditGroupTourPricing"
            : "CreateGroupTourPricing",
          isActive: false,
        },
        {
          text: "Cancellation",
          name: "cancellation",
          linkName: this.tourId
            ? "EditGroupTourCancellation"
            : "CreateGroupTourCancellation",
          isActive: false,
        },
      ];
    },
  },
  destroyed() {
    this.resetGroupTourDetails();
  },
};
</script>
<style scoped>
.sheet {
  @apply fixed z-50 bg-white;
  @apply transition-all duration-700 ease-in-out;
  @apply h-full w-full;
}

.dialog {
  @apply fixed z-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
}

.btn-price {
  @apply bg-black-base p-2 w-full text-white text-sm;
  @apply fixed bottom-17 sm:bottom-20 z-40 hover:bg-black-light;
  @apply flex justify-center items-center;
  @apply duration-300 transition-all ease-out sm:hidden;
  @apply focus:ring-black-darker focus:border-black-base;
  @apply focus:outline-none focus:scale-95 focus:ring-offset-2 focus:ring-2;
}

.btn-price span {
  @apply ml-1;
}
</style>
