<template>
  <PageComponent showBackBtn :showMenuBtn="false">

    <section class="px-4 pb-20 mt-5 md:px-12 min-h-screen">
      <div class="wrapper">
        <Tab :tabItems="tabItems"/>
      </div>
      <template v-if="isLoadingData">
        <Loading/>
      </template>
      <template v-else>
        <div v-show="tabItems[0].isActive" class="wrapper">
          <div class="flex justify-between w-full">
            <div class="request-value">
              <span class="font-sans text-xl font-semibold text-black-base">
                  {{ tourDetails.title }}
                </span>
            </div>
            <div class="buttons flex">
              <ButtonLink
                  :route="`/group-tours/edit/${tourId}/`"
                  text="Edit Tour"
                  class="btn-offer"
              />

              <button class="btn-cancel ml-2" @click="deleteTour">
                Delete tour
              </button>

            </div>
          </div>
          <Divider class="my-4"/>
          <div class="flex justify-between w-calc-full">
            <h6 class="font-sans text-black-lightest">Tour ID</h6>
            <p class="mt-1 font-sans text-black-base">{{ tourDetails.id }}</p>
          </div>
          <Divider class="my-4"/>
          <div class="flex justify-between w-calc-full">
            <h6 class="font-sans text-black-lightest">
              Language of communication
            </h6>
            <p class="mt-1 font-sans text-black-base">
              {{ tourDetails.language }}
            </p>
          </div>
          <Divider class="my-4"/>
          <div class="flex justify-between mt-3 w-calc-full">
            <h6 class="font-sans text-black-lightest">Title</h6>
            <p class="mt-1 font-sans text-black-base">
              {{ tourDetails.title }}
            </p>
          </div>
          <Divider class="my-4"/>
          <div class="flex justify-between mt-3 w-calc-full">
            <h6 class="font-sans text-black-lightest">Tour creation date</h6>
            <p class="mt-1 font-sans text-black-base">
              <template v-if="tourDetails.createdAt">
                <timeago
                    :auto-update="60"
                    :datetime="tourDetails.createdAt"
                >
                </timeago>
              </template>
            </p>
          </div>
          <Divider class="my-4"/>
          <div class="flex justify-between mt-3 w-calc-full">
            <h6 class="font-sans text-black-lightest">Destination</h6>
            <p class="mt-1 font-sans text-black-base">
              {{ tourDetails.destination }}
            </p>
          </div>
          <Divider class="my-4"/>
          <div class="flex justify-between mt-3 w-calc-full">
            <h6 class="font-sans text-black-lightest">Available dates</h6>
            <div class=" font-sans text-black-base text-right">
              <p class="mt-1" v-for="(date,index) in  tourDetails.tourDates" :key="'date_'+index">
                {{ humanReadable(date) }}
              </p>
            </div>
          </div>

          <Divider class="my-4"/>
          <div class="flex justify-between mt-3 w-calc-full">
            <h6 class="font-sans text-black-lightest">Duration (in days)</h6>
            <p class="mt-1 font-sans text-black-base">
              {{ tourDetails.duration }}
            </p>
          </div>
          <Divider class="my-4"/>
          <div class="flex justify-between mt-3 w-calc-full">
            <h6 class="font-sans text-black-lightest">Single room price</h6>
            <p class="mt-1 font-sans text-black-base">
              {{ tourDetails.currency }} {{ tourDetails.singleRoomPrice }}
            </p>
          </div>

          <Divider class="my-4"/>
          <div class="flex justify-between mt-3 w-calc-full">
            <h6 class="font-sans text-black-lightest">Shared room price</h6>
            <p class="mt-1 font-sans text-black-base">
              {{ tourDetails.currency }} {{ tourDetails.sharedRoomPrice }}
            </p>
          </div>
          <Divider class="my-4"/>

          <div class="mt-3 w-calc-full">
            <h6 class="font-sans text-black-lightest mb-1">Experiences</h6>

            <div v-for="(itinerary,index) in tourDetails.itinerary "
                 :key="index+'_'+itinerary.title" class="mb-4">
              <Divider class="my-4"/>
              <p class=" font-sans text-black-base font-bold mb-1">
                {{
                  itinerary.title
                }}
              </p>
              <div v-for="(experience,index) in itinerary.experiences " :key="experience.name+'_'+index">
                <p class="mt-1 font-sans">{{ experience.name }}</p>
              </div>
            </div>

          </div>
          <template
              v-if="
                tourDetails.accommodation &&
                tourDetails.accommodation !== 'null'
              "
          >
            <Divider class="my-4"/>
            <div class="mt-4 w-calc-full">
              <h6 class="font-sans text-black-lightest">Accommodation</h6>
              <div v-for="(accommodation,index) in tourDetails.accommodation"
                   :key="'accommodation_key_'+accommodation.name+'_'+index">
                <Divider class="my-4"/>
                <div class="mt-1 font-sans text-black-base">
                  <p>
                    {{ accommodation.name }}
                  </p>
                  <p class="text-sm text-teal-base">{{ accommodation.address }}</p>
                </div>
              </div>
            </div>
          </template>
          <Divider class="my-4"/>
        </div>

        <div v-show="tabItems[1].isActive" id="preview-offer" class="wrapper">
          <div v-if="tourBookings">
            <div v-for="(booking, index) in tourBookings " :key="`booking-${index}`">
              <GroupTourBookingsTableRow :booking="booking"/>
            </div>
            <div>
            </div>
          </div>
        </div>
      </template>
    </section>

  </PageComponent>
</template>

<script>
import {PageComponent, ButtonLink, GroupTourBookingsTableRow} from "@/components";
import Loading from "@/components/Preloaders/Loading";
import {mapActions, mapGetters} from "vuex";
import Tab from "@/components/Tabs/Tab";
import Divider from "@/components/Layout/Divider";
import {humanReadableDateRange} from "../../shared/dateHandler";

export default {
  name: "GroupTour",
  components: {
    PageComponent,
    Loading,
    Tab,
    ButtonLink,
    Divider,
    GroupTourBookingsTableRow
  },
  props: {
    tourId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      tabItems: [
        {
          name: "Tour details",
          isActive: true,
          textColor: "text-black-base",
        },
        {
          name: "Bookings",
          isActive: false,
          textColor: "text-grey-dark",
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      isLoadingData: 'groupTours/isLoadingData',
      tourDetails: 'groupTours/tourDetails',
      tourBookings: 'groupTours/tourBookings'
    }),

  },
  methods: {
    ...mapActions({
      getGroupTour: 'groupTours/getGroupTour',
      getGroupTourBookings: 'groupTours/getGroupTourBookings',
      deleteGroupTour: 'groupTours/deleteGroupTour'
    }),
    deleteTour() {
      this.deleteGroupTour(this.tourId).then(() => {
        this.$router.push('/group-tours')
      })
    },
    humanReadable(dateRange) {
      return humanReadableDateRange(dateRange)
    }
  },
  mounted() {
    this.getGroupTour(this.tourId);
    this.getGroupTourBookings(this.tourId);
  }
}
</script>


<style scoped>
.page {
  @apply flex overflow-hidden bg-grey-light h-100vh;
}

.page-wrapper {
  @apply relative self-start w-full h-full overflow-y-auto pb-12;
}

.row-title {
  @apply font-sans text-black-lightest;
}

.age-label {
  @apply inline-block p-1.5 px-3 bg-black-darker text-white rounded-md mr-1 mb-1;
}

.wrapper {
  @apply relative max-w-3xl mt-12 transform -translate-x-1/2 left-1/2;
}

.empty-state-action {
  @apply hidden sm:flex mt-12;
}

.btn-offer {
  @apply w-32 hidden sm:flex;
}

.bottom-bar {
  @apply w-full fixed sm:hidden bg-white p-3 bottom-0 border-t border-grey-light;
}

.btn-cancel {
  @apply w-32 h-11;
  @apply bg-white shadow-sm rounded focus:outline-none focus:scale-95;
  @apply transition duration-300 ease-in-out;
  @apply focus:ring-offset-2 focus:ring-2 focus:ring-red-dark focus:border-red-dark;
  @apply hover:bg-red-base hover:text-white;
  @apply text-red-base;
}

.dialog {
  @apply fixed z-40 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
}

</style>